.card-divider {
   padding: 0.5em;

   h2 {
      color: $white;

      @include breakpoint(medium) {
         font-size: 1.755rem;
      }
   }

   border-width: 0px;
   @include border-radius(10px 10px 0 0);
}

.card-section {
   padding: 5vh;
   border: 2px solid $black;
   font-size: 100%;
   @include border-radius(0 0 8px 8px);

   @include breakpoint(medium) {
      padding: 2em 3em;
   }
}

.card-style {
   margin: 2em auto;
   @include border-radius(10px);
}

.blue-divider {
   @include card-divider($background: $myBlue1);
}

.maroon-divider {
   @include card-divider($background: $myMaroon);
}

.black-divider {
   @include card-divider($background: $black);
}