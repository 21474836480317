.highlight {
  // font-weight: bold;
  background-color: lighten(#fab500,20%);
}

div.searchheading br {
  display: none;
}

div.searchheading {
  color: #0a0a0a;
  font-size: 130%;
  font-weight: bold;
  margin-top: 2em;
}

div.result_pagescount br {
  display: none;
}

.summary {
  font-size: 100%;
  font-style: italic;
  margin-top: 10px;
}

.results {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 90%;
  line-height: 140%;
}

.result_title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
  /*font-weight: bold;*/
  text-decoration: underline;
  color: #0a0a0a;
}

.result_title a {
  color: #0000FF;
  font-weight: bold;
}

.result_pages {
  margin-top: 1em;
}

.description {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
  font-style: italic;
  color: #1E9E1E;
}

.result_block {
  margin-top: 15px;
  margin-bottom: 15px;
  clear: left;
}

.infoline {
  display: none;
  font-size: 80%;
  /*font-weight: bold;*/
}

div.input-group-button input.button {
  margin-top: 0;
}

form.zoom_searchform {
  display: none;
}
