h1,
h2,
h3,
h4 p.subtitle {
   color: $black;
}

h1.title {
   font-weight: bold;
   font-size: 150%;
   text-align: center;

   @include breakpoint(medium) {
      font-size: 250%;
   }
}

h2.title {
   font-size: 2rem;

   @include breakpoint(large) {
      font-size: 2.25em;
   }
}

.center {
   text-align: center;
}

.right {
   text-align: right;
}

.ind {
   padding-left: 1em;
}

.justify {
   text-align: left;

   @include breakpoint(medium) {
      text-align: justify;
   }
}

// .indent {
//   margin-left: 2em;
// }

.lg {
   font-size: 150%;
   color: $medium-blue;
}

.lgr {
   font-size: 150%;
   color: $myRed;
}

.bold {
   font-weight: bold;
}

.warning {
   color: $myRed;
}

form p.warning {
   color: $myRed;
   margin: 0 0 1em 0;
}

.blog {
   font-size: 1.5em;
   font-weight: bold;
   max-width: 90%;
   margin: 1em auto 0 auto;
}

.interserver {
   text-align: center;
   background-color: $white;
   font-size: 125%;
   font-weight: bold;
   color:$medium-blue;
   border: 5px solid $medium-blue;
   @include border-radius(6px);
   width: 14em;
   margin: 0 auto 2em auto;
}

.underline,
span.u {
   text-decoration: underline;
}

p,
blockquote,
div.callout,
li {
   -webkit-hyphens: auto;
   // -webkit-hyphenate-limit-before: 3;
   // -webkit-hyphenate-limit-after: 3;
   // -webkit-hyphenate-limit-chars: 6 3 3;
   // -webkit-hyphenate-limit-lines: 2;
   // -webkit-hyphenate-limit-last: always;
   // -webkit-hyphenate-limit-zone: 6%;
   -moz-hyphens: auto;
   // -moz-hyphenate-limit-chars: 6 3 3;
   // -moz-hyphenate-limit-lines: 2;
   // -moz-hyphenate-limit-last: always;
   // -moz-hyphenate-limit-zone: 6%;
   -ms-hyphens: auto;
   -ms-hyphenate-limit-chars: 6 3 3;
   -ms-hyphenate-limit-lines: 2;
   -ms-hyphenate-limit-last: always;
   -ms-hyphenate-limit-zone: 6%;
   hyphens: auto;
   // hyphenate-limit-chars: 6 3 3;
   // hyphenate-limit-lines: 2;
   // hyphenate-limit-last: always;
   // hyphenate-limit-zone: 6%;
   // text-align: justify;
}

p.quote {
   font-style: italic;
   margin: 0 1.25em 1em 1.25em;
   font-size: 95%;
}