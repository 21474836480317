.padding-1 {
   padding: 1em;
}

.padding-2 {
   padding: 2em;
}

.padding-top-1 {
   padding-top: 1em;
}

.padding-top-2 {
   padding-top: 2em;
}

.padding-bottom-1 {
   padding-bottom: 1em;
}

.padding-bottom-2 {
   padding-bottom: 2em;
}

.padding-left-1 {
   padding-left: 1em;
}

.padding-left-2 {
   padding-left: 2em;
}

.padding-right-1 {
   padding-right: 1em;
}

.padding-right-2 {
   padding-right: 2em;
}

.margin-1 {
   margin: 1em;
}

.margin-2 {
   margin: 2em;
}

.margin-0 {
   margin: 0;
}

.margin-top-1 {
   margin-top: 1em;
}

.margin-top-0_5 {
   margin-top: 0.5em;
}

.margin-top-1_5 {
   margin-top: 1.5em;
}

.margin-top-2 {
   margin-top: 2em;
}

.margin-top-3 {
   margin-top: 3em;
}

.margin-bottom-1 {
   margin-bottom: 1em;
}

.margin-bottom-0 {
   margin-bottom: 0;
}

.margin-bottom-0_5 {
   margin-bottom: 0.5em;
}

.margin-bottom-1_5 {
   margin-bottom: 1.5em;
}

.margin-bottom-2 {
   margin-bottom: 2em;
}

.margin-bottom-3 {
   margin-bottom: 3em;
}

.margin-left-1 {
   margin-left: 1em;
}

.margin-left-2 {
   margin-left: 2em;
}

.margin-right-1 {
   margin-right: 1em;
}

.margin-right-2 {
   margin-right: 2em;
}

.margin-top-0 {
   margin-top: 0;
}

.margin-bottom-0 {
   margin-bottom: 0;
}

.font-weight-bold {
   font-weight: bold;
}

.font-color-black {
   color: $black;
}

.font-color-white {
   color: $white;
}

.font-color-red {
   color: $myRed;
}

.font-color-blue {
   color: $medium-blue;
}

.font-size-1 {
   font-size: 1em;
}

.font-size-2 {
   font-size: 2em;
}

.font-size-3 {
   font-size: 3em;
}

.border-1 {
   border-width: 1px;
}

.border-2 {
   border-width: 2px;
}

.border-none {
   border: none;
}