div.donate {
  padding-right: 0.5em;

  a.button {
    padding: .5em 1em;
    border-radius: 20px;
    background-color: $dodger-blue;
    border: 10px solid $medium-blue;
    color: $white;
  }

  form.paypal {
    border: none;
    margin-bottom: 1em;
  }
}

a.match.button {
  border-radius: 10px;
  background-color: $dodger-blue;
  border: 5px solid $medium-blue;
  color: $white;
}

