$sm-perct : 90%;
$med-perct: 80%;
$lg-perct : 65%;

body {
   font-family: Helvetica, Arial, sans-serif;
   font-size: calc(1rem + 0.5vw);
   /*   background:
   linear-gradient(rgba(0, 0, 0, 0.08),rgba(0, 0, 0, 0.08)),
   url(/assets/img/gray4.jpg);
   background-repeat: repeat;
*/
   /*   background:*/
   background-color: rgba(0, 0, 0, 0.15);
   background-image: url(/img/gray4.jpg);
   blend-mode: darken;
   background-repeat: repeat;
}

p {
   max-width: 60ch;
   margin-left: auto;
   margin-right: auto;
}

.wrap {
   width: 90%;
   margin: 0 auto;
   max-width: 1100px;
}

@mixin border-radius($list) {
   border-radius: $list;
   -moz-border-radius: $list;
   -webkit-border-radius: $list;
}

@mixin box-shadow($list, $color) {
   box-shadow: $list $color;
   -moz-box-shadow: $list $color;
   -webkit-box-shadow: $list $color;
}

.container {
   width: $sm-perct;

   @if $med-perct < $sm-perct {
      @include breakpoint(medium) {
         width: $med-perct;
      }
   }

   @include breakpoint(large) {
      width: $lg-perct;
      max-width: 700px;
   }

   margin: 0 auto;
}


.box {
   margin: 3em auto 2em auto;
   width: 90%;
   max-width: 960;
   background-color: $myBlue1;
   padding: 0.5em;
   border: 0;
   @include box-shadow(0px 6px 4px, $dark-gray);
   @include border-radius(8px);

   h2 {
      color: $white;
      text-align: center;

      @include breakpoint(medium) {
         font-size: 2.25rem;
      }
   }
}

.box1 {
   margin: 3em auto 2em auto;
   width: 90%;
   max-width: 960;
   background-color: $white;
   padding: 0.5em;
   border: 5px solid $myBlue1;
}

.content-gray {
   padding: 2em 3em;
   background-color: rgba(0, 0, 0, .05);
   @include border-radius(8px);
   border: 3px solid $black;
   margin: 1em auto 0 auto;
}

.content-white {
   padding: 2em 3em;
   background-color: $white;
   @include border-radius(8px);
   border: 3px solid $black;
   margin: 1em auto 0 auto;
}

.bottom-verse {
   font-style: italic;
   font-weight: bold;
   font-size: 1.1em;
   margin: 1.5em 0 2em 0;
   color: $teal;
   text-align: justify;
}

cite {
   font-style: normal;
   font-weight: 550;
   font-size: 1em;
   text-align: left;
   color: $teal;
}

table tbody {
   outline: 3px solid $black;
}

dd {
   margin-bottom: 1.5em;
}

.video {
   margin: 1.5em auto 2em auto;
   border: 4px solid white;
   outline: 1.5px solid $black;
}

hr {
   height: 1px;
   width: 100%;
   background: $dark-gray;
   margin-top: 0;
}

.callout {
   margin: auto;
   padding: 2em 2em;

   @include breakpoint(medium) {
      padding: 2em 3em;
   }

   @include border-radius(8px);

   &.callout.frame {
      border: solid black 2px;
      margin-bottom: 2em;
   }
}

.media-object {
   margin-bottom: 2em;
}

button.close-button {
   span {
      color: $medium-gray;
   }

   outline-width: 0;
}

a:hover,
input[type="submit"]:hover {
   filter: brightness(140%);
}

a img:hover,
input[type="image"]:hover {
   filter: brightness(105%);
}


.mb2 {
   margin-bottom: 2em;
}

.blue-bdr {
   border-width: 5px;
   border-color: $myBlue1;
}

.ph-med {
   display: block;
   margin: 0 auto;
   padding: 1.5em;
   font-size: 1.5em;
   border-radius: 20px;
}

.ph-sm {
   display: block;
   margin: 0 auto;
   padding: 1em;
   font-size: 1em;
   border-radius: 10px;
}