// $ladle-blue   : #0070aa;
$medium-blue  : #0000CD;
$myRed        : #FA0000;
$teal         : #008080;
$facebook-blue: #0000cc;
$myMaroon     : #900000;
$rose         : #C02E4C;
$myBlue1      : #0059a6;
// $button-gray  : #555555;
$dodger-blue  : #1589FF;
// $navy-blue    : #000080;