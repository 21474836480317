#widemenu.top-bar {
  padding-left: 1em;

  a:link,
  a:visited,
  a:hover {
    color: $white;
    font-size: 1em;
  }
}

.off-canvas {
  padding: 3rem 1rem 1rem 1rem;

  ul.vertical {
    font-weight: bold;
    line-height: .75;

    a {
      color: $white;
    }

    ul.nested {
      font-weight: normal;
      margin-left: 0;
    }

    li.menu-text {
      color: $white;
    }
  }
}

button.close-button:hover {
  border: 0;
}