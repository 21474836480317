.contact {

   // input,
   // textarea {
   //    border: 1px solid $black;
   // }

   input[type="submit"] {
      background: $myBlue1;
      color: $white;
      border: 0;
   }
}

div.org {
   border: 0 !important;
   clip: rect(0 0 0 0) !important;
   height: 1px !important;
   margin: -1px !important;
   overflow: hidden !important;
   padding: 0 !important;
   position: absolute !important;
   width: 1px !important;
}

// label b,
// label span {
//    font-size: 1.3em;
// }

// .formpad {
//   padding: 1em 2em;
// }

.reveal {
   padding: 2em 1em 1em 1em;
}

.search-form {
   width: 100%;
   margin: 0 auto;

   @include breakpoint(medium) {
      width: 70%;
   }
}