.accordion-title {
   &:before {
      margin: 0px;
      // padding: 0px;
      @include css-triangle(.35em, $white, down);
   }

   .is-active>&:before {
      @include css-triangle(.35em, $white, up);
   }

   font-size : 110%;
   background-color: $myMaroon;
   color : $white;
   border : none;
   padding : 1rem 1.5rem 1rem 2rem;
}

.accordion-title:hover,
.accordion-title:focus {
   @extend .accordion-title;
}

.accordion {
   background-color: $white;
   border: none;
   margin: 0 auto 1em;
}

.accordion-content {
   width: 100%;
   color: $black;
   border-top: 10px solid transparent;

   a {
      i.fi-social-facebook {
         color: $facebook-blue;
         font-size: 1.25em;
      }

      i.fi-social-twitter {
         font-size: 1.25em;
      }
   }
}