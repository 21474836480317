img {
   max-width: 100%;
   height: auto;
}

img.banner {
   width: 100%;
   height: auto;
}

.smaller_thumb {
   width: 80%;

   @include breakpoint(large) {
      width: 75%;
   }
}

img.img-frame {
   display: block;
   margin: 0 auto 0.5em auto;
   border: 4px solid white;
   outline: 1.5px solid $black;
   // @include border-radius(10%); opacity: 1.0;
}

img.img-frame1 {
   display: block;
   margin: 0 auto 1em auto;
   border: 5px solid $black;
   @include border-radius(10%);
}

img.img-frame2 {
   display: block;
   margin: 1em auto 1.5em auto;
   border: 4px solid white;
   outline: 1.5px solid $black;
   // @include border-radius(10%); opacity: 1.0;
}

img.circular {
   display: block;
   margin: 0 auto 0.5em auto;
   border: 2px solid $black;
   border-radius: 50%;
   -moz-border-radius: 50%;
   -webkit-border-radius: 50%;
}

h1.img-title {
   text-align: center;
   margin-top: 0em;
   margin-bottom: 1em;
}

p.icons {
   max-width: none;
   text-align: right;
   margin: 0 0 0 0;

   i.fi-social-facebook {
      color: $facebook-blue;
      font-size: 2em;
   }

   i.fi-social-twitter {
      font-size: 2em;
   }

   i.fi-social-instagram {
      font-size: 2em;
      padding-right: 0.5em;
      color: $rose;
   }

   i.fi-magnifying-glass {
      font-size: 2em;
   }
}

.responsive {
   max-width: 50%;
   vertical-align: middle;
   border: 0;
   margin: .5em 1em 0 0;
}

.bdr-bot {
   border-bottom: 5px solid $black;
}

.learn-more {
   font-size: 1.1em;

   @include breakpoint(medium) {
      font-size: inherit;
   }

   color: lighten(#6c9ef8, 10%);
   line-height: 75%;
   cursor: pointer;
   margin-bottom: 1.5em;
   margin-top: 0.5em;
   text-align: center;
}

.image-label {
   text-align: center;
   margin-bottom: 0;
   padding: 0;
   font-size: 1.1em;

   @include breakpoint(medium) {
      font-size: inherit;
   }

   color: #444444;
   line-height: 1.1;
}

.center-image {
   display: block;
   margin: 0 auto 1em;
   width: 95%;

   @include breakpoint(medium) {
      width: 75%;
   }
}

.center-img {
   display: block;
   margin: 0 auto;
}

i.fi-social-instagram {
   color: $rose;
   font-size: 1.2em;
}

i.fi-social-facebook {
   color: $facebook-blue;
   font-size: 1.2em;
}

i.fi-star {
   color: $myRed;
   font-size: 1em;
}